<template>
  <div>
    <div
        v-for="(section,index) in sections"
        :key="index"
    >
      <b-card
          v-if="fieldsData"
          :header="section.name"
      >
        <b-list-group horizontal="sm">
          <div class="row">
            <div
                v-for="(sectionFields,k) in fieldsData[section.id]"
                :key="k"
                class="col-6 mb-1"
            >
              <b-list-group-item><span class="name">{{ sectionFields.name }}</span>
                <br>
                <small
                    v-if="sectionFields.value.value"
                    class="type text-muted"
                >{{ sectionFields.value.value }}</small>
                <small
                    v-else
                    class="type text-muted"
                >{{ $i18n.t('no_value') }}</small>
              </b-list-group-item>
            </div>
          </div>
        </b-list-group>
      </b-card>
    </div>
  </div>
</template>

<script>
import {BListGroupItem, BListGroup} from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem
  },
  props: ['sections', 'fields'],
  data() {
    return {
      fieldsData: {}
    }
  },
  created() {
    this.formingFields();
  },
  methods: {
    formingFields() {
      this.sections.forEach((section) => {
        const innerFields = [];
        section.fields.forEach(field => {
          const matchField = this.fields.find(f => f.field_id === field.id)
          if (matchField) {
            innerFields.push({
              id: field.id,
              name: field.name,
              value: JSON.parse(matchField.value)
            });
          } else innerFields.push({id: field.id, name: field.name, value: null});
        });
        this.$set(this.fieldsData, section.id, innerFields);
      })
    }
  }
}
</script>

<style scoped>

</style>
