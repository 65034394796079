<template>
  <div>
    <div class="actions-buttons mb-1">
<!--      <b-button-->
<!--          v-if="isCanDo('updateContract')"-->
<!--          class="mr-1"-->
<!--          variant="outline-primary"-->
<!--      >-->
<!--        {{ $i18n.t('user.contracts.addAttachments') }}-->
<!--      </b-button>-->
      <b-button
          v-if="contractData && contractData.isExistPdf"
          class="mr-1"
          variant="outline-primary"
          @click="downloadPdf"
      >
        {{ $i18n.t('user.contracts.downloadPdf') }}
      </b-button>
      <b-button
          v-if="isCanDo('updateContract')"
          :to="{name: 'update-contract', params: { id: $route.params.id } }"
          class="mr-1"
          variant="outline-primary"
      >
        {{ $i18n.t('user.contracts.editContract') }}
      </b-button>
      <b-button
          v-if="isCanDo('deleteContract')"
          class="mr-1"
          variant="outline-danger"
          @click="deleteContract"
      >
        {{ $i18n.t('user.contracts.deleteContract') }}
      </b-button>
    </div>
    <b-container>
      <b-row v-if="contractData">
        <b-col>
          <b-card-group>
            <div>
              <b-card
                  :header="contractData.title"
                  class="mainCard"
              >
                <div>
                  <b-list-group
                      class="m-1"
                      horizontal
                  >
                    <b-list-group-item :style="{ backgroundColor: categoryBgColor }">
                      {{ $i18n.t('admin.category.category') }} : {{ contractData.category.name }}
                    </b-list-group-item>
                    <b-list-group-item :variant="statusVariant">
                      {{ $i18n.t('user.contracts.status') }} : {{ getContractStatus(contractData.status) }}
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </b-card>
            </div>
            <div>
              <b-card>
                <span v-if="tags">
                   <b-badge
                       v-for="(tag,index) in tags"
                       :key="index"
                       class="marginRight-2px"
                       variant="success"
                   >
                  {{ tag }}
                </b-badge>
                </span>
                <span v-else>{{ $i18n.t('user.contracts.noTags') }}</span>
              </b-card>
            </div>
            <Duration
                :contract="contractData"
            />
            <Sections
                :fields="contractData.fields"
                :sections="contractData.category.sections"
            />
          </b-card-group>
        </b-col>
        <b-col>
          <upload-pdf
              v-if="isCanDo('updateContract')"
              :contract-id="contractData.id"
              :is-exist-pdf="contractData.isExistPdf"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {BCard, BCardGroup, BListGroup, BListGroupItem, BContainer, BBadge} from 'bootstrap-vue';
import Sections from "@/components/contract/show/sections.vue";
import Duration from "@/components/contract/show/durationShow.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import uploadPdf from "@/components/contract/uploadPdf.vue";

export default {
  components: {
    BCard,
    BCardGroup,
    BListGroup,
    BListGroupItem,
    BContainer,
    BBadge,
    Sections,
    uploadPdf,
    Duration
  },
  data() {
    return {
      contractId: this.$route.params.id,
      repository: RepositoryFactory.create('contracts'),
      contractData: undefined,
    }
  },
  computed: {
    statusVariant() {
      switch (this.contractData.status) {
        case 0:
          return 'dark'
        case 1:
          return 'success'
        case 2:
          return 'danger'
        case 3:
          return 'warning'
        default:
          return 'primary'
      }
    },
    categoryBgColor() {
      return this.contractData.category.color
    },
    tags() {
      if (this.contractData.tags) return JSON.parse(this.contractData.tags);
      return null;
    }
  },
  created() {
    this.getContractData();
  },
  methods: {
    async downloadPdf() {
        this.repository.getPdf(this.contractId).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${process.env.VUE_APP_Name}_file.pdf`); // Set desired file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }).catch(error => {
        })
    },
    getContractData() {
      this.repository.get(this.contractId).then(res => {
        this.contractData = res.data.data
      });
    },
    deleteContract() {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.repository.delete(this.$route.params.id).then(response => {
            this.$router.push({name: 'contracts'});
            this.$swal({
              icon: 'success',
              title: `${this.$i18n.t('Deleted')}!`,
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.mainCard .card-header {
  font-size: x-large;
  font-variant: all-small-caps;
  font-weight: 800;
  font-family: inherit;
  border-bottom: inset !important;
}

.mainCard .list-group-item {
  margin-right: 11px;
  border-radius: 0.358rem;
  border-width: initial;
}

.card-group {
  display: unset;
}

.marginRight-2px {
  margin-right: 2px;
}
</style>
