<template>
  <div>
    <b-card
        :header="$i18n.t('user.contracts.duration')"
    >
      <b-list-group>
        <div class="row">
          <b-col
v-if="contract.startDate"
cols="12"
class="mb-1"
>
            <b-list-group-item><span class="name">{{
                $i18n.t("user.contracts.durationOptions.durationType")
              }}</span>
              <br>
              <small
                  class="type text-muted"
              >{{ this.getDurationType(contract.durationType) }}</small>
            </b-list-group-item>
          </b-col>
          <b-col
v-if="contract.startDate"
cols="6"
class="mb-1"
>
            <b-list-group-item><span class="name">{{
                $i18n.t("user.contracts.durationOptions.startDate")
              }}</span>
              <br>
              <small
                  class="type text-muted"
              >{{ contract.startDate }}</small>
            </b-list-group-item>
          </b-col>
          <b-col
v-if="contract.noticePeriod"
cols="6"
class="mb-1"
>
            <b-list-group-item><span class="name">{{
                $i18n.t("user.contracts.noticePeriodInMonthsName")
              }}</span>
              <br>
              <small
                  class="type text-muted"
              >{{ contract.noticePeriod }}</small>
            </b-list-group-item>
          </b-col>
          <b-col
v-if="contract.duration"
cols="6"
class="mb-1"
>
            <b-list-group-item><span class="name">{{
                this.$i18n.t("user.contracts.durationInMonthsName")
              }}</span>
              <br>
              <small
                  class="type text-muted"
              >{{ contract.duration }}</small>
            </b-list-group-item>
          </b-col>
          <b-col
v-if="contract.lastPossibleTerminationDate"
cols="6"
class="mb-1"
>
            <b-list-group-item><span class="name">{{
                $i18n.t("user.contracts.lastPossibleTerminationDateName")
              }}</span>
              <br>
              <small
                  class="type text-muted"
              >{{ contract.lastPossibleTerminationDate }}</small>
            </b-list-group-item>
          </b-col>
          <b-col
v-if="contract.endDate"
cols="6"
class="mb-1"
>
          <b-list-group-item><span class="name">{{
              $i18n.t("user.contracts.endDateName")
            }}</span>
            <br>
            <small
                class="type text-muted"
            >{{ contract.endDate }}</small>
          </b-list-group-item>
          </b-col>
          <b-col
v-if="contract.renewalPeriod"
cols="6"
>
            <b-list-group-item><span class="name">{{
                $i18n.t("user.contracts.automaticRenewalPeriodInMonthsName")
              }}</span>
              <br>
              <small
                  class="type text-muted"
              >{{ contract.renewalPeriod }}</small>
            </b-list-group-item>
          </b-col>
        </div>
      </b-list-group>
      <br>
    </b-card>
  </div>
</template>

<script>
import {BListGroup, BListGroupItem} from "bootstrap-vue";

export default {
  components: {
    BListGroup,
    BListGroupItem
  },
  props: ['contract']
}
</script>

<style scoped>

</style>
